<template>
    <div class="view">
        <div class="head">
            <div class="back" @click="back()">
                <back :size="'20px'"/>
            </div>
            <div class="head_title">Elections</div>
            <div class="r">
                <router-link to="/elections/learn">
                    <div class="btn">Learn More</div>
                </router-link>
            </div>
        </div>
        <div class="main">
            <div class="title">Current Election</div>
            <div class="box">
                <div>
                    <election :size="'25px'"/>
                    <div class="text">There is no election currently</div>
                </div>
            </div>
            <div class="title">Previous Elections</div>
            <div class="box">
                <div>
                    <list :size="'25px'"/>
                    <div class="text">Previous elections result will show here</div>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
import back from '../../icons/back.vue'

import election from '../../icons/election.vue'
import list from '../../icons/order.vue'

export default {
    components: {
        back,
        election, list
    },
    
    methods: {
        back() {
            this.$router.go(-1)
        },
    }
}
</script>

<style scoped>

    .view {
        background-color: white;
    }

    .head {
        display: flex;
        gap: 20px;
        align-items: center;
        padding: 15px 15px;
        border-bottom: 1px solid #ddd;
        fill: var(--main);

        position: relative;
    }
    .head_title {
        font-weight: 500;
    }
    .r {
        position: absolute;
        right: 15px;
    }
    .btn {
        font-size: 10px;
        background-color: var(--main);
        color: var(--trans);
        color: white;
        font-weight: 500;
        border-radius: 5px;
        padding: 7px 10px;
    }


    .main {
        height: calc(100vh - 112px);
        overflow: auto;
        box-sizing: border-box;
        padding: 15px;
    }

    .title {
        font-weight: 500;
    }
    .box {
        padding: 70px 50px;
        text-align: center;
        fill: #555;
    }
    .text {
        font-size: 12px;
        margin-top: 5px;
        color: gray;
        font-weight: 500;
    }
</style>